/* ===================================================================
 *  .skills-table
 *
 * ------------------------------------------------------------------- */

.skills-table, tr {
  border: 1px solid black;
  text-align: center;
  height: 1.2em;
  padding: 1.5rem 3.2rem;
  border-collapse: collapse;
  
}
.skills-table, 
th, td
 {
  text-align: center;
  border: 1px solid black;
  padding: 1.5rem 3.2rem;

}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}
/* ===================================================================
 * skills
 *
 * ------------------------------------------------------------------- */
 .row-left-aligned {
  margin-left: 5em;
  margin-right: auto;
  max-width: 1140px;
  width: 50%;
}
 
 
 .s-skills {
  
  padding-top: 12.8rem;
  padding-bottom: 4.2rem;
  background-color: #ffffff;
}

.s-skills .row {
  max-width: 1140px;
}

.s-skills > [class*="about-"] {
  margin-bottom: 15.2rem;
}

.s-skills > [class*="about-"]:last-child {
  margin-bottom: 0;
}


/* Create two equal columns that floats next to each other */
.column-skills {

  flex-grow: 50%;
  width: 30%;
  padding: 1em;
  
}

.column-skills::after {
  content: '\00a0';
  width: 14.4rem;
}

/* Clear floats after the columns */
.row::after {
  content: "";
  display: table;
  clear: both;
 padding: 0.8em;
}

/* ==============================================*/
/*	skill bars
/*----------------------------------------------*/
.bars {
  width: 75%;
  float: left;
  padding: 0;
  text-align:
    left;
}

.bars .skills {
  margin-top: 46px;
  list-style: none;
}

.bars li {
  position: relative;
  margin-bottom: 60px;
  background: #CCC;
  height: 42px;
  border-radius: 12px;
}

.bars li em {
  font: 15px 'opensans-bold', sans-serif;
  color: #aaa0a0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  position: relative;
  top: -36px;
}

.bar-expand {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding-right: 24px;
  background: #313131;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  border-radius: 4px;
  border: 0px solid black;
  padding: 0;
}

.bars::before {
  content: '\00a0';
  width: 14.4rem;
}

.java {
  width: 80%;
  -moz-animation: java 2s ease;
  -webkit-animation: java 2s ease;
  animation: java 2s ease;
}

.javascript {
  width: 65%;
  -moz-animation: javascript 2s ease;
  -webkit-animation: javascript 2s ease;
  animation: javascript 2s ease;
}

.csharp {
  width: 55%;
  -moz-animation:  csharp 2s ease;
  -webkit-animation: csharp 2s ease;
  animation: csharp 2s ease;
}

.selenium {
  width: 90%;
  -moz-animation: selenium 2s ease;
  -webkit-animation: selenium 2s ease;
  animation: selenium 2s ease;
}

.python {
  width: 70%;
  -moz-animation: python 2s ease;
  -webkit-animation: python 2s ease;
  animation: python 2s ease;
}

.restassured {
  width: 50%;
  -moz-animation: restassured 2s ease;
  -webkit-animation: restassured 2s ease;
  animation: restassured 2s ease;
}

.robotframework {
  width: 75%;
  -moz-animation: robotframework 2s ease;
  -webkit-animation: robotframework 2s ease;
  animation: robotframework 2s ease;
}


@-moz-keyframes java {
  0% {
    width: 0px;
  }
  
  100% {
    width: 80%;
  }
}
@-webkit-keyframes java {
  0% {
    width: 0px;
  }
  
  100% {
    width: 80%;
  }
}

@keyframes java {
  0% {
    width: 0px;
  }

  100% {
    width: 80%;
  }
}

@-moz-keyframes javascript {
  0% {
    width: 0px;
  }

  100% {
    width: 65%;
  }
}

@-webkit-keyframes javascript {
  0% {
    width: 0px;
  }

  100% {
    width: 65%;
  }
}
@keyframes javascript {
  0% {
    width: 0px;
  }

  100% {
    width: 65%;
  }
}


@-webkit-keyframes csharp {
  0% {
    width: 0px;
  }

  100% {
    width: 55%;
  }
}


@-moz-keyframes csharp {
  0% {
    width: 0px;
  }

  100% {
    width: 55%;
  }
}
@keyframes csharp {
  0% {
    width: 0px;
  }

  100% {
    width: 55%;
  }
}


@-moz-keyframes selenium {
  0% {
    width: 0px;
  }

  100% {
    width: 90%;
  }
}
@-webkit-keyframes selenium {
  0% {
    width: 0px;
  }

  100% {
    width: 90%;
  }
}
@keyframes selenium {
  0% {
    width: 0px;
  }

  100% {
    width: 90%;
  }
}

@-moz-keyframes python {
  0% {
    width: 0px;
  }

  100% {
    width: 70%;
  }
}
@-webkit-keyframes python {
  0% {
    width: 0px;
  }

  100% {
    width: 70%;
  }
}
@keyframes python {
  0% {
    width: 0px;
  }

  100% {
    width: 70%;
  }
}
@-moz-keyframes restassured {
  0% {
    width: 0px;
  }

  100% {
    width: 50%;
  }
}
@-webkit-keyframes restassured {
  0% {
    width: 0px;
  }

  100% {
    width: 50%;
  }
}
@keyframes restassured {
  0% {
    width: 0px;
  }

  100% {
    width: 50%;
  }
}

@-moz-keyframes robotframework {
  0% {
    width: 0px;
  }

  100% {
    width: 75%;
  }
}
@-webkit-keyframes robotframework {
  0% {
    width: 0px;
  }

  100% {
    width: 75%;
  }
}
@keyframes robotframework {
  0% {
    width: 0px;
  }

  100% {
    width: 75%;
  }
}
